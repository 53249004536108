@import url('https://fonts.googleapis.com/css2?family=Varela&display=swap');

.navbar {
    width: 100%;
    z-index: 10;
    background: #ffffff;
    position: relative;
    text-align: center;
}



.nav-box {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: .6rem 0;
}


.navbar-logo {
  width: 180px;
  z-index: 2;
  padding: 0 1rem;
}


.navbar-logo img {
  width: 100%;
  height: 100%;
  display: block;
}

.navbar .icon {
  font-size: 1.4rem;
}

.navbar .logo .icon {
    font-size: 4rem;
    margin-right: 1rem;
}

.hamburger {
    display: none;
    padding: 1rem;
    margin: auto 0;
    color: rgb(221, 142, 23);
}

.navbar .nav-menu {
    height: 100%;
    align-items: center;
    display: flex;
    padding: 1rem;
}

.navbar li {
    width: max-content;
    padding: .3rem .8rem;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

.navbar li a {
  color: rgb(0, 0, 0);
  font-family: 'Varela', sans-serif;  
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;

}

.navbar li a:hover {
  color: rgb(224, 132, 13) !important;
  font-weight: 500;
}

  .menu-item .sub__menus li a {
    color: #1b1a1a;
    font-size: 13px;
    font-weight: 100;
  }

  .menu-item .sub__menus li a:hover {
    color: rgb(224, 132, 13) !important;
    font-weight: 500;
  }

/* -----------------------------------------------------> SUB MENU */
.sub__menus {
    position: absolute;
    display: none;
    background: #fffffffd;
    width: auto;
    padding: 2px 0 0 0;
    margin-top: 6px;
    z-index: 1000;
  }



  /* -----------------------------------------------------> DROPDOWN ARROW */
  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    padding-top: 5px;
    top: 10px;
    font-size: 18px;
  }


.logo-hide {
  display: none;
}

@media screen and (max-width: 1200px) {


  .logo-hide {
    display: block;
  }
  
  .logo-hide2 {
    display: none;
  }

  .nav-box {
    justify-content: space-between;
}

    .navbar .hamburger {
        display: block;
        z-index: 15;
    }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 75%;
        height: 80vh;
        top: 0;
        left:-100%;
        align-items: center;
        justify-content: center;
        background: rgb(255, 255, 255);
        transition: 0.5s ease-in;
        color: white;
    }

    .navbar .active {
        left: 0;
    }

    .navbar li a {
      font-family: 'roboto', sans-serif;
      font-size: 14px;
      text-align: left;
      width: 100%;
  }

    .navbar li {
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      width: 100%;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.171);
  }


    .navbar button {
        font-size: 1.8rem;
    }

    .menu-item .sub__menus {
      background-color: rgb(221, 142, 23);
      width: 100%;
      left: 0;
    }


    .menu-item .sub__menus li a {
      color: #ffffff;
      font-size: 13px;
      font-weight: 100;
    }
  
    .menu-item .sub__menus li a:hover {
      color: rgb(29, 28, 27) !important;
      font-weight: 500;
    }


  .logo-hide {
      display: block;
  }

  .nav-box {
    border-radius: 0;    
    background-color: #fff;
}



}


