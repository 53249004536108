@import url('https://fonts.googleapis.com/css2?family=Cookie&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Francois+One&display=swap');

video {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 60vh;
}


.hero .hero-container .content {
    width: 100%;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
}

.hero h1 {

    font-size: 5rem;
    text-align: center;
    color: white;
    font-family: 'Francois One', sans-serif;
    letter-spacing: 2px;
}

.hero h2 {
    font-size: 1.5rem;
    color: white;
    letter-spacing: 3px;
    text-align: center;
}

.hero-container p {
    font-size: 3rem;
    color:rgba(243, 237, 237, 0.849);
    font-family: 'Cookie', cursive;
    text-align: center;
}

.hero .hero-btn button {
    cursor: pointer;
    border-radius: 5px;
    border: none;
    background-color: #1a6d0fbd;
}

.hero-btn {
    padding: 0;
    text-align: center;
}

.hero .hero-btn button:hover {
  color: var(--primary-color);
  background-color: rgb(241, 242, 245);
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {



    .hero p {
        font-size: 2rem;
    }

}


@media screen and (max-width:700px) {

    .hero h1 {
        font-size: 3rem;
    }
    
    .hero p {
        font-size: 2rem;
    }

    .hero h2 {
        font-size: .8rem;
        color: white;
    }

    .hero .hero-container .content {
        top: 55%;
    }


    }
    