@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.asd {
    width: 100%;
    height: 60vh;
    background: rgba(0, 0, 0, 0.349);
    position: relative;
  }
  
  .asd:before {
    content: '';
    position: absolute;
    background: url('../../assets/mental-health-submenu-bg-min.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.asd {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.asd .cocaine-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}

.asd h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.asd h1 {
    font-size: 2rem;
}

}