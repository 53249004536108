@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.about-container {
    width: 100%;
    height: auto;
    position: relative;
  }
  
  .about-container:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.05;
  }

.about-content {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 3rem 1rem;
}

.about-content-left {
    width: 400px;
    text-align: center;
    margin: auto;
}

.about-content-left img {
    width: 100%;
    border-radius: 1rem;
}

.about-content-right {
    width: 600px;
    margin: auto;
}


.about-content-right h2 {
    font-size: 1.5rem;
}

.about-content-right h1 {
    text-transform: uppercase;
    color: #fd9b2f;
    font-size: 1rem;
    letter-spacing: 8px; 
}




/* button */

.about-btn {
    width: 100%;
    text-align: center;
}

.about-cta {
    position: relative;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    justify-content: center;
   }
   
   .about-cta:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    background: #fd9b2f;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
   }
   
   .about-cta span {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: var(--primary-color);
   }
   
   .about-cta svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--primary-color);
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
   }
   
   .about-cta:hover:before {
    width: 100%;
    background: #f0a351;
   }
   
   .about-cta:hover svg {
    transform: translateX(0);
   }
   
   .about-cta:active {
    transform: scale(0.95);
   }



@media screen and (max-width:940px) {

    .about-content {
        flex-wrap: wrap-reverse;
    }
    

    .about-content-right {
        width: 100%;
    }

    .about-content-right h1 { 
        text-align: center;
    }

    .about-content-right h2 {
        font-size: 1.2rem;
        text-align: center;
    }

    .about-content-right p {
        text-align: justify;
    }

    .about-content-left {
        width: 100vw;
    }



}
