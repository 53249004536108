.section3 {
    width: 100%;
    height: auto;
    position: relative;
    padding: 2rem 0;
    background-color: rgb(15, 56, 35);

  }
  
  .section3:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.05;
  }

  .section3-content {
    padding: 1rem;
  }

  .menu-programs-header h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 3px;
    color: var(--secondary-color);
  }

.section3-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: .5rem;
    padding: 1rem;
}

.section3-flexItem {
    width: 300px;
    height: auto;
}

.section3-flexItem img {
    width: 100%;
    border-radius: .2rem;
}

.section3-flexItem h1 {
    color: white;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 300;
    padding: .5rem 0;
}

.section3-flexItem {
    text-align: center;
}

.section3-flexItem button {
    background-color: rgba(202, 138, 20, 0.781);
    color: white;
    border: none;
    font-weight: 500;
}

.section3-flexItem button:hover {
    background-color: rgba(255, 255, 255, 0.815);
    color: var(--secondary-color);
    border: none;
}


@media  screen and (max-width: 700px) {
    
    .section3-flexItem {
        width: 100%;
    }

    
}



