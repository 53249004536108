
.sa-php {
    width: 100%;
    height: 50vh;
    background: rgba(114, 76, 34, 0.342);
    position: relative;
  }
  
  .sa-php:before {
    content: '';
    position: absolute;
    background: url('../../assets/programs-php-bg.webp') no-repeat center center/cover;
    height: 50vh;
    width: 100%;
    z-index: -1;
  }


.sa-php {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.sa-php .sa-php-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}


.sa-php h1 {
    color: white;
    font-size: 3rem;
    text-align: center;
    font-weight: 300;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

    .sa-php .sa-php-content h1 {
        font-size: 2rem;
    }
    
    }